import { Container } from "react-bootstrap";
import { FadeLoader } from "react-spinners";

const SPINNER_COLOR = "#e0ff00";

export default function OverlaySpinner() {
    return (
        <Container fluid id="overlay">
            <FadeLoader color={SPINNER_COLOR} id="overlaySpinner" role="loadingStatus"></FadeLoader>
        </Container>
    );
}
