import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import NavBarItem from "./NavBarItem";
import NavBarItemWithSubMenu from "./NavBarItemWithSubMenu";

export default function PrimaryNavBar({ navItems }) {
    const navigationMenuItemDtoList = navItems?.data?.data?.navigationMenuItemDtoList;

    return (
        <Navbar id="primaryNavBar" expand="lg" variant="dark">
            <Container className="lcp lcp-nav-container lcp-primary-nav-bar-container" fluid>
                <Navbar.Brand
                    className="ml-auto lcp-navbar-brand"
                    as={Link}
                    to="https://lightpathfiber.com/"
                    target={"_blank"}
                    name="primaryLogo"
                >
                    <Container className="lcp lcp-container lcp-nav-logo-container" fluid>
                        <img
                            src={process.env.REACT_APP_STATIC_IMAGES + "/lp-logo.png"}
                            alt=""
                            className="lcp-nav-logo"
                            width={260}
                            height={60.97}
                        />
                    </Container>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        {navigationMenuItemDtoList?.map((item, index) => {
                            const key = index + item?.navName;
                            if (item?.children?.length > 0) {
                                return (
                                    <NavBarItemWithSubMenu
                                        key={key}
                                        children={item?.children}
                                        navName={item?.navName}
                                    />
                                );
                            } else {
                                return <NavBarItem key={key} navName={item?.navName} />;
                            }
                        })}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
