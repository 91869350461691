import axios from "axios";
import { XHR_STATUS } from "../static/xhrStatus";

//Create the client with static properties
const client = axios.create({
    baseURL: process.env.REACT_APP_APP_SERVER_URL,
});

// Create the client with public endpoints.
const clientPublic = axios.create({
    baseURL: process.env.REACT_APP_APP_SERVER_URL,
});

// Create the client that includes authentication token.
const clientAuthenticationToken = axios.create({
    baseURL: process.env.REACT_APP_APP_SERVER_URL,
});

//Create the client with static properties
const clientPdf = axios.create({
    baseURL: process.env.REACT_APP_APP_SERVER_URL,
    responseType: "blob",
});

//Redirect when intercepting a 403
const authorizationRedirect = (error, navigate) => {
    const xhrStatus = error?.response?.data?.xhrStatus?.statusCode;
    if (xhrStatus === XHR_STATUS.XHR_USER_INSUFFICIENT_PERMISSION) {
        navigate("/permission-issue");
    } else {
        if (xhrStatus === XHR_STATUS.XHR_ACCOUNT_ROOT_CUSTOMER_NUMBER_MISMATCH) {
            return;
        } else {
            navigate("/account-profile-problem");
        }
    }
};

// Add a request interceptor
clientAuthenticationToken.interceptors.request.use(
    // Runs before the request is sent
    (config) => {
        const token = localStorage.getItem("lcpAuthToken");

        //Add the token to the request headers
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
    },
    // Triggers when the request has an error
    (error) => {
        return Promise.reject(error);
    }
);

// Add a request interceptor
clientPdf.interceptors.request.use(
    // Runs before the request is sent
    (config) => {
        const token = localStorage.getItem("lcpAuthToken");

        //Add the token to the request headers
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
    },
    // Triggers when the request has an error
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor
const responseInterceptorPdf = (navigate) =>
    clientPdf.interceptors.response.use(
        // Any status code that lie within the range of 2xx cause this function to trigger
        (response) => {
            return response;
        },
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        (error) => {
            if (error.message === "Network Error") {
                navigate("/ext-temporary-issue");
            }
            switch (error?.response?.status) {
                case 401:
                    if (window.location.pathname !== "/account-profile-problem") {
                        sessionStorage.setItem("from", window.location.pathname);
                        navigate("/signin");
                    }
                    break;
                case 403:
                    authorizationRedirect(error, navigate);
                    break;
                case 504:
                case 502:
                    navigate("/ext-temporary-issue");
                    break;
                default:
                // No action as all other status codes need to be handled at page level
            }
            return Promise.reject(error);
        }
    );

// Add a request interceptor
const requestInterceptor = client.interceptors.request.use(
    // Runs before the request is sent
    (config) => {
        const token = localStorage.getItem("lcpAuthToken");

        //Add the token to the request headers
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
    },
    // Triggers when the request has an error
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor
const responseInterceptor = (navigate) =>
    client.interceptors.response.use(
        // Any status code that lie within the range of 2xx cause this function to trigger
        (response) => {
            return response;
        },
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        (error) => {
            if (error.message === "Network Error") {
                navigate("/ext-temporary-issue");
            }
            switch (error?.response?.status) {
                case 400:
                    if (error?.response?.data?.xhrStatus?.statusCode === XHR_STATUS.XHR_SITE_WORK_IN_PROGRESS) {
                        navigate("/site-work", {
                            state: {
                                uiNotificationDto: error.response.data.uiNotificationDto,
                            },
                        });
                    }
                    break;
                case 401:
                    if (window.location.pathname !== "/account-profile-problem") {
                        if (error?.response?.data?.xhrStatus?.statusCode === XHR_STATUS.XHR_INACTIVE_USER) {
                            navigate("/user-issue");
                        } else {
                            sessionStorage.setItem("from", window.location.pathname);
                            navigate("/signin");
                        }
                    }
                    break;
                case 403:
                    authorizationRedirect(error, navigate);
                    break;
                case 500:
                case 504:
                    switch (error?.response?.data?.xhrStatus?.statusCode) {
                        case XHR_STATUS.XHR_DATABASE_FAILURE:
                            navigate("/technical-issue");
                            break;
                        default:
                            navigate("/temporary-issue");
                            break;
                    }
                    break;
                case 502:
                    navigate("/technical-issue");
                    break;
                // No action as all other status codes need to be handled at page level
                default:
                    break;
            }
            return Promise.reject(error);
        }
    );

// Add a response interceptor
const publicResponseInterceptor = (navigate) =>
    clientPublic.interceptors.response.use(
        // Any status code that lie within the range of 2xx cause this function to trigger
        (response) => {
            return response;
        },
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        (error) => {
            switch (error?.response?.status) {
                case 500:
                case 504:
                    switch (error?.response?.data?.xhrStatus?.statusCode) {
                        case XHR_STATUS.XHR_DATABASE_FAILURE:
                            navigate("/technical-issue");
                            break;
                        default:
                            navigate("/ext-temporary-issue");
                            break;
                    }
                    break;
                case 502:
                    navigate("/technical-issue");
                    break;
                // No action as all other status codes need to be handled at page level
                default:
                    break;
            }
            return Promise.reject(error);
        }
    );

export {
    client,
    clientPublic,
    clientPdf,
    clientAuthenticationToken,
    requestInterceptor,
    publicResponseInterceptor,
    responseInterceptor,
    responseInterceptorPdf,
};
