import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Outlet, useLocation, useNavigate } from "react-router";
import Footer from "../components/Footer";
import OverlaySpinner from "../components/OverlaySpinner";
import useAuthenticateToken from "../hooks/useAuthenticateToken";
import useGetBasicAccountInfoDtoList from "../hooks/useGetBasicAccountInfoDtoList";
import useGetDisplayableUiNotificationDtoList from "../hooks/useGetDisplayableUiNotificationDtoList";
import useGetNavItemsInfo from "../hooks/useGetNavItemsInfo";
import { client } from "../services/client";
import { navigateToPreviousLink } from "../static/common";

// This route exists separate from PrivateRoute due to the rule of hooks
export default function AuthenticationRoute() {
    // Hook for the current location
    const location = useLocation();

    // State to determine if a page is loading
    const [pageLoading, setPageLoading] = useState();

    // Hook for navigation
    const navigate = useNavigate();

    // Authenticate the users token
    const authenticateToken = useAuthenticateToken([location?.pathname]);

    // Navigation point for onSuccess of changing the root customer
    const [fallbackPath, setFallbackPath] = useState();

    // Update the selected logged in user root customer number
    // https://react-query.tanstack.com/guides/mutations
    const changeRootCustomer = useMutation(
        ["/lcp-user-management-service/changeRootCustomer"],
        (customerNumberAsString) => {
            return client.post("/lcp-user-management-service/changeRootCustomer", {
                customerNumberAsString,
            });
        },
        {
            onSuccess: (innerData) => {
                // If the fallback page exists and the new root customer number does not match the old one,
                // navigate to the previous link or the fallback page, while clearing the fallback page state.
                if (fallbackPath && rootCustomerNumber !== innerData?.data?.loggedInUserCustomerNumber) {
                    navigateToPreviousLink(location, navigate, fallbackPath);
                }
            },
        }
    );

    // Reset fallback path whenever the location changes
    useEffect(() => {
        setFallbackPath(undefined);
    }, [location]);

    // Retrieve the basicAccountInfoDtoList
    const getBasicAccountInfoDtoList = useGetBasicAccountInfoDtoList(
        [location?.pathname, changeRootCustomer?.data?.data?.loggedInUserCustomerNumber],
        authenticateToken.isSuccess
    );

    // Data for the nav bar
    const getNavItemsInfo = useGetNavItemsInfo(
        [getBasicAccountInfoDtoList?.data?.data?.rootCustomerNumber],
        getBasicAccountInfoDtoList?.isSuccess
    );

    // List of UI Notifications
    const getDisplayableUiNotificationDtoList = useGetDisplayableUiNotificationDtoList(
        [getBasicAccountInfoDtoList?.data?.data?.rootCustomerNumber],
        getBasicAccountInfoDtoList?.isSuccess
    );

    // Abbreviated access to the root customer
    const rootCustomerNumber = getBasicAccountInfoDtoList?.data?.data?.rootCustomerNumber;

    // Outlet => Renders the child routes element, if there is one.
    // "context" prop is accessed in child routes as a hook, example below
    // const outletContext = useOutletContext();
    // Query loading indicator global, modals are outside of normal loading sequence
    return (
        <>
            {(authenticateToken?.isLoading ||
                getBasicAccountInfoDtoList?.isLoading ||
                getNavItemsInfo?.isLoading ||
                getDisplayableUiNotificationDtoList?.isLoading ||
                pageLoading) && <OverlaySpinner />}
            <Outlet
                context={{
                    authenticateToken,
                    getBasicAccountInfoDtoList,
                    getNavItemsInfo,
                    getDisplayableUiNotificationDtoList,
                    changeRootCustomer,
                    rootCustomerNumber,
                    setFallbackPath,
                    setPageLoading,
                }}
            />
            <Footer />
        </>
    );
}
