import { useQuery } from "react-query";
import { client } from "../services/client";

//Query for getBasicAccountInfoDtoList
const getBasicAccountInfoDtoListQuery = () => {
    return client.post("/lcp-user-management-service/getBasicAccountInfoDtoList");
};

/**
 * Hook that encapsulates the useQuery hook for getBasicAccountInfoDtoList.
 *
 * @param dependencies
 *        An array of Objects representing dependencies for the query.
 *        If any of the dependencies change, query should refresh unless configured not to.
 *        The param defaults to an empty array, but the hardcoded name of the query is always appended.
 *
 * @param enabledBoolean
 *        A boolean to determine if the query is enabled or not, defaults to false.
 *
 * @returns A useQuery hook with all included functionality and states you would expect from useQuery.
 *          https://tanstack.com/query/latest/docs/framework/react/reference/useQuery
 */
export default function useGetBasicAccountInfoDtoList(dependencies = [], enabledBoolean = false) {
    return useQuery(
        ["/lcp-user-management-service/getBasicAccountInfoDtoList", ...dependencies],
        getBasicAccountInfoDtoListQuery,
        {
            enabled: enabledBoolean,
        }
    );
}
