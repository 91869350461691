import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";

export default function Footer({ hasLogo = true }) {
    return (
        <Container
            fluid
            style={{
                backgroundColor: "#f1f1f1",
                padding: "95px 65px 95px 65px",
            }}
        >
            <Row className="footerRow">
                <Col className="text-center" style={{ marginBottom: "25px" }}>
                    {hasLogo && (
                        <img
                            style={{ maxWidth: "191px" }}
                            src={process.env.REACT_APP_STATIC_IMAGES + "/lp-logo-blk.png"}
                            alt="Logo"
                            width={191}
                            height={44.79}
                        ></img>
                    )}
                </Col>
                <Col>
                    <Nav className="footerNav">
                        <Nav.Item>
                            <Nav.Link
                                className="footerLink"
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://lightpathfiber.com/about-us"
                                alt="About Us"
                            >
                                About Us
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                className="footerLink"
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://careers.lightpathfiber.com/"
                                alt="Careers"
                            >
                                Careers
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                className="footerLink"
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://lightpathfiber.com/privacy-policy"
                                alt="Privacy Policy"
                            >
                                Privacy Policy
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                className="footerLink"
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://lightpathfiber.com/legal"
                                alt="Terms & Conditions"
                            >
                                Terms &amp; Conditions
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="lcp-footer-icons-container">
                            <Nav.Link
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://linkedin.com/company/Lightpath"
                                alt="LinkedIn"
                                className="lcp-footer-icon"
                                name="LinkedIn"
                            >
                                <Image
                                    src={process.env.REACT_APP_STATIC_IMAGES + "/linked-in-icon-blk.png"}
                                    className="lcp-footer-icon"
                                    alt="linkedin-icon"
                                    width={30}
                                    height={30}
                                />
                            </Nav.Link>
                            <Nav.Link
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://www.youtube.com/user/OptimumLightpath"
                                alt="YouTube"
                                className="lcp-footer-icon"
                                style={{ marginBottom: "-6px" }}
                                name="Youtube"
                            >
                                <Image
                                    src={process.env.REACT_APP_STATIC_IMAGES + "/youtube-icon-blk.png"}
                                    className="lcp-footer-icon"
                                    alt="youtube-icon"
                                    width={30}
                                    height={30}
                                />
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
        </Container>
    );
}
