import { useQuery } from "react-query";
import { client } from "../services/client";

const getDisplayableUiNotificationDtoListQuery = () => {
    return client.post("/lcp-support-service/getDisplayableUiNotificationDtoList");
};

//Data for the global notifications
//https://react-query.tanstack.com/guides/queries
export default function useGetDisplayableUiNotificationDtoList(dependencies = [], enabledBoolean = false) {
    return useQuery(
        ["/lcp-support-service/getDisplayableUiNotificationDtoList", ...dependencies],
        getDisplayableUiNotificationDtoListQuery,
        {
            enabled: enabledBoolean,
        }
    );
}
