import { useQuery } from "react-query";
import { client } from "../services/client";

const authenticateTokenQuery = () => {
    return client.post("/lcp-user-management-service/authenticateToken");
};

export default function useAuthenticateToken(dependencies = [], refetchInterval = false) {
    return useQuery(["/lcp-user-management-service/authenticateToken", ...dependencies], authenticateTokenQuery, {
        onSuccess: () => {
            if (window.snapEngageVisibleFlag === false) {
                window.snapEngageVisibleFlag = true;
                window.SnapEngage?.showButton();
            }
        },
        refetchInterval: refetchInterval,
    });
}
