import { useCallback, useState } from "react";
import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";
import Navbar from "react-bootstrap/Navbar";
import { useTranslation } from "react-i18next";
import { Link, useOutletContext } from "react-router-dom";
import { createFilter } from "react-select";
import AsyncSelect from "react-select/async";
import { rootCustomerNumberSelectStyles } from "../static/selectStyles";

export default function RootCustomerNumberNavBar() {
    //Function from i18next for translation
    const { t: translate } = useTranslation(["select-company", "signin"]);

    //Outlet Context
    const outletContext = useOutletContext();

    //State for the select input count, used to display a pre-search message
    const [selInputCount, setSelInputCount] = useState(3);

    //State for the Select Root Customer value
    //Exists due to req to have label to the side and keep the select clear
    //As this is cleared instantly do not use it for dependency updating where you need the value
    const [selRootCustomer, setSelRootCustomer] = useState("");

    //Shortened accessor for other page data
    const pageData = outletContext?.getBasicAccountInfoDtoList?.data?.data;

    //useMutation from OutletContext for changing the root customer number
    const changeRootCustomer = outletContext?.changeRootCustomer;

    //Map the Account Info DTO List to the format AsyncSelect expects.
    const accountList = pageData?.basicAccountInfoDtoList.map((account) => {
        return {
            value: account.rootCustomerNumber,
            label: account.accountNumberAndName,
        };
    });

    //Handler for the selRootCustomer React Select changing.
    const handleSelRootCustomerChange = useCallback(
        (event) => {
            //Mutate selected root customer
            changeRootCustomer.mutate(event.value);

            //Update the value inside of the select to an empty string
            setSelRootCustomer("");
        },
        [changeRootCustomer]
    );

    //Remove the token from local storage
    const handleLogout = useCallback(() => {
        localStorage.removeItem("lcpAuthToken");
        window.userLoggedInFlag = false;
    }, []);

    //Handler to display the pre-search message in the dropdown
    const noOptionsMessage = useCallback(
        (input) => {
            if (accountList?.length < 10) {
                return "No results found";
            }
            if (input.inputValue.length < 3) {
                return "Please enter " + selInputCount + " or more characters";
            } else {
                return "No results found";
            }
        },
        [accountList?.length, selInputCount]
    );

    //Function with callback to populate account list.
    //Data is pre-fetched before this point.
    //https://react-select.com/async
    const loadOptions = useCallback(
        (inputValue, callback) => {
            //Early exit for a small amount of available accounts.
            if (accountList?.length < 10) {
                callback(accountList);
                return;
            }
            //Prevent actual load of data to avoid choppiness, if list grows add pagination/virtualization.
            if (inputValue?.length < 3) {
                callback([]);
            } else {
                //Fire callback with accountList as param.
                callback(accountList);
            }
        },
        [accountList]
    );

    //Update the select input count based on the absolute value of the length of your input minus three.
    const handleSelInputCount = useCallback((input) => {
        setSelInputCount(Math.abs(input?.length - 3));
    }, []);

    return (
        <>
            <Navbar expand="lg" id="accountSelectNavBar" className="justify-content-end">
                <Nav>
                    <p className="my-auto lcp-customer-label">
                        {pageData &&
                            translate("selectCompany.company") +
                                pageData?.rootCustomerNumber +
                                " - " +
                                pageData?.rootCustomerName}
                    </p>
                    {accountList?.length > 1 && (
                        <NavItem className="nav-select">
                            <AsyncSelect
                                value={selRootCustomer}
                                cacheOptions
                                defaultOptions
                                loadOptions={loadOptions}
                                onChange={handleSelRootCustomerChange}
                                onInputChange={handleSelInputCount}
                                placeholder={translate("selectCompany.choose.account")}
                                noOptionsMessage={noOptionsMessage}
                                filterOption={createFilter({
                                    ignoreAccents: false,
                                })}
                                styles={rootCustomerNumberSelectStyles}
                                label="select-company"
                            />
                        </NavItem>
                    )}

                    <Nav.Link className="lcp-text-hoverable lcp-logout" as={Link} to="/signin" onClick={handleLogout}>
                        {pageData !== undefined && translate("signin.logout", { ns: "signin" })}
                    </Nav.Link>
                </Nav>
            </Navbar>
        </>
    );
}
