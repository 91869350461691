export const routePostFix = " | Lightpath";

export const routeDictionary = {
    account_history: "/account-history",
    account_summary: "/account-summary",
    account_support_team: "/account-support-team",
    add_checking_account: "/add-checking-account",
    add_contact: "/add-contact",
    add_invoice_subscriber: "/add-invoice-subscriber",
    add_location: "/add-location",
    add_notification: "/add-notification",
    add_user: "/add-user",
    all_ebf_subscribers: "/all-ebf-subscribers",
    all_invoice_subscribers: "/all-invoice-subscribers",
    all_users: "/manage-all-users",
    articles: "https://lightpathfiber.com/articles",
    assign_contact_to_location: "/assign-contact-to-location",
    billing: "/billing",
    bill_pay: "/manage-online-payments",
    change_password: "/change-password",
    circuit_telno: "/circuit-telno",
    contact_details: "/contact-details",
    contact_locations: "/locations",
    contacts: "/contacts",
    copy_locations: "/copy-locations",
    create_support_request: "/create-ticket",
    customer_spotlights: "https://lightpathfiber.com/articles/customer-spotlights",
    ebf_subscribers: "/ebf-subscribers",
    edit_checking_account: "/edit_checking_account",
    edit_contact: "/edit-contact",
    edit_invoice_subscriber: "/edit-invoice-subscriber",
    edit_notification: "/edit-notification",
    edit_user: "/edit-user",
    home: "/",
    hosted_resources: "/hosted-resources",
    hosted_station_review: "/hosted-station-review",
    invoice_subscribers: "/invoice-subscribers",
    invoices: "/invoices",
    metrics_reporting: "/metrics-reporting",
    monthly_recurring_charges: "/monthly-recurring-charges",
    my_profile: "/my-profile",
    one_time_payment: "/one-time-payment",
    order_details: "/order-details",
    orders: "/orders",
    page_not_found: "/page-not-found",
    paperless_billing: "/paperless-billing",
    payment_history: "/payment-history",
    payment_methods: "/manage-payment-methods",
    permission_issue: "/permission-issue",
    product_information: "/product-information",
    product_portals: "/product-portals",
    products: "/product-services",
    report_preferences: "/report-preferences",
    reports: "/view-reports",
    request_access: "/request_access",
    search_invoices: "/search-invoices",
    search_maintenance_contacts: "/search-maintenance-contacts",
    signin: "/signin",
    support_documents: "/support-documents",
    temporary_issue: "/temporary-issue",
    ui_notifications: "/notifications",
    upload_reports: "/upload-reports",
    user_issue: "/user-issue",
    users: "/manage-users",
    view_network_status: "/view-network-status",
    tickets: "/view-tickets",
    view_ticket_details: "/view-ticket-details",
    view_ticket_scheduled_events: "/scheduled-maintenance",
    view_ticket_scheduled_events_details: "/scheduled-maintenance-details",
    view_ticket_scheduled_events_calendar: "/scheduled-maintenance-calendar",
};

export const routeDictionaryReverse = {
    "/": "home",
    "/account-history": "account_history",
    "/account-profile-problem": "account_profile_problem",
    "/account-summary": "account_summary",
    "/account-support-team": "account_support_team",
    "/add-checking-account": "add_checking_account",
    "/add-contact": "add_contact",
    "/add-invoice-subscriber": "add_invoice_subscriber",
    "/add-location": "add_location",
    "/add-notification": "add_notification",
    "/add-user": "add_user",
    "/all-ebf-subscribers": "all_ebf_subscribers",
    "/all-invoice-subscribers": "all_invoice_subscribers",
    "/assign-contact-to-location": "assign_contact_to_location",
    "/billing": "billing",
    "/change-password": "change_password",
    "/circuit-telno": "circuit_telno",
    "/contact-details": "contact_details",
    "/contact-locations": "contact_by_locations",
    "/contacts": "contacts",
    "/copy-locations": "copy_locations",
    "/create-ticket": "create_support_request",
    "/ebf-subscribers": "ebf_subscribers",
    "/edit-checking-account": "edit_checking_account",
    "/edit-contact": "edit_contact",
    "/edit-invoice-subscriber": "edit_invoice_subscriber",
    "/edit-notification": "edit_notification",
    "/edit-user": "edit_user",
    "/ext-temporary-issue": "temporary_issue",
    "/hosted-resources": "hosted_resources",
    "/hosted-station-review": "hosted_station_review",
    "/invoice-subscribers": "invoice_subscribers",
    "/invoices": "invoices",
    "/locations": "contact_locations",
    "/manage-all-users": "all_users",
    "/manage-online-payments": "bill_pay",
    "/manage-payment-methods": "payment_methods",
    "/manage-users": "users",
    "/metrics-reporting": "metrics_reporting",
    "/monthly-recurring-charges": "monthly_recurring_charges",
    "/my-profile": "my_profile",
    "/notifications": "ui_notifications",
    "/one-time-payment": "one_time_payment",
    "/order-details": "order_details",
    "/orders": "orders",
    "/page-not-found": "page_not_found",
    "/paperless-billing": "paperless_billing",
    "/payment-history": "payment_history",
    "/permission-issue": "permission_issue",
    "/portal-tour": "portal_tour",
    "/product-information": "product_information",
    "/product-portals": "product_portals",
    "/product-services": "products",
    "/report-preferences": "report_preferences",
    "/request-access": "request_access",
    "/request-reset-password": "request_reset_password",
    "/reset-password": "reset_password",
    "/scheduled-maintenance": "view_ticket_scheduled_events",
    "/scheduled-maintenance-details": "view_ticket_scheduled_events_details",
    "/scheduled-maintenance-calendar": "view_ticket_scheduled_events_calendar",
    "/search-invoices": "search_invoices",
    "/search-maintenance-contacts": "search_maintenance_contacts",
    "/signin": "signin",
    "/site-work": "site_work",
    "/support-documents": "support_documents",
    "/technical-issue": "technical_issue",
    "/temporary-issue": "temporary_issue",
    "/upload-reports": "upload_reports",
    "/user-issue": "user_issue",
    "/view-network-status": "view_network_status",
    "/view-reports": "reports",
    "/view-tickets": "tickets",
    "/view-ticket-details": "view_ticket_details",
    "/view-user": "view_user",
};
