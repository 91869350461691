/* eslint-disable no-extend-native */
/* eslint-disable no-useless-escape */
/**
 * This file defines the constants and functions used for entire portal.
 */

export const formatMoney = (number, locale = "en-US", currency = "USD") => {
    return number?.toLocaleString(locale, {
        style: "currency",
        currency: currency,
    });
};

/**
 * Yes and No option with the string value of "true" and "false".
 */
export const yesNoBooleanSelectOptions = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
];

/**
 * Definition of the default timeout delay value, in milliseconds.
 */
export const DEFAULT_TIMEOUT_DELAY_MSEC = 5;
/**
 * Definition of the array to store the number of rows to display in a grid.
 */
export const GRID_ROWS_PER_PAGE_VALUES = [10, 25, 50, 100];

/**
 * Definition of the default number of rows per page to initially display.
 */
export const DEFAULT_GRID_ROWS_PER_PAGE = GRID_ROWS_PER_PAGE_VALUES[0];

/**
 * Definition of the default text display of records being viewed, e.g 'showing 1 - 3 of 10'
 */
export const DEFAULT_RECORDS_TEXT_VALUE = "Showing {0} - {1} of {2}";

// Initialize the months array.
export const MONTH_NAMES = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

/**
 * Definition of the regex to match numeric digits only.
 *
 * Only valid are numeric digits 0-9
 */
export const REGEX_DIGITS_ONLY = /^\d+$/;

/**
 * Definition of the regex to match numeric decimal numbers.
 *
 * Only valid are numeric digits 0-9 and *ONE* period (.)
 */
export const REGEX_DECIMAL_NUMBER = /^(0|[1-9]\d*)(\.\d+)?$/;

/**
 * Definition of the regex to match US money format, with one decimal.
 *
 * Only valid are numeric digits 0-9 and *ONE* period (.) with 2 numbers following, no leading zeroes.
 */
export const REGEX_DECIMAL_NUMBER_CURRENCY = /^\d+(?:\.?\d{0,2})?$/;

/**
 * Definition of the regex to match U.S. dollar currency amounts. This REGEX is based on
 * patterns found at the following URLs: http://regexlib.com/Search.aspx?k=currency and
 * http://regexlib.com/RETester.aspx?regexp_id=70 (set the engine to Client-side engine,
 * then select JavaScript below where the regular expression appears). Replace the regex
 * that appears with the one below (omit the leading and trailing slashes).
 *
 * Rewritten to supress leading zeroes (unless zero dollars) --Rick
 *
 * Note that this REGEX allows dollar signs and commas, so if you don't want them in a
 * variable, you'll need to strip them out by using the abcp.stripUSDCurrencyCharacters()
 * function.
 */
export const REGEX_CURRENCY_USD = /^\$?(0|[1-9]\d{0,2}(,\d{3})*)(\.\d\d)?$/;

/**
 * Definition of the regex to match phone number.
 *
 * Only valid are digits, dashes and spaces.
 */
export const REGEX_PHONE_NUMBER = /^[\d\s-]+$/;

/**
 * Definition of the regex to match the allowed characters for first and last name.
 *
 * Only valid are alphabetic A to Z and a to z, hyphen (-), apostrophe (') and period (.). Spaces allowed.
 */
export const REGEX_NAME_ALLOWED_CHARACTERS = /^[A-Za-z\-'. ]+$/;

/**
 * Definition of the regex to match the allowed characters for a username.
 *
 * Only valid are alphabetic A to Z and a to z, hyphen (-), period (.) and underscore (_). Spaces are NOT allowed.
 */
export const REGEX_USER_NAME_ALLOWED_CHARACTERS = /^[0-9A-Za-z\-._]+$/;

/**
 * Definition of the regex for date formats.  Use this for checking if a date is in the correct format.
 *
 */
export const REGEX_DATE_FORMAT = /^(0?[1-9]|1[012])\/(0?[1-9]|[12]\d|3[01])\/[1-9]\d{3}$/; // We all know that 02/31/2022 isn't right.

/**
 * Definition of the regex to match payment method nicknames.
 *
 * Only valid are alphabetic a to z, numbers 0-9, hyphen (-), period (.), ampersand(&),
 * comma(,), apostrophe ('), left and right parentheses and underscore(_).
 */
export const REGEX_PAYMENT_METHOD_NICKNAME = /^[A-Za-z0-9.\-_&,'()\x20]+$/;

/**
 * Definition of the regex to match access hours when creating a ticket.
 */
export const NS_REGEX_VALID = /^[a-zA-Z0-9\!\@\#\$\%\&\*\(\)\[\]\_\+\=\,\?\.\'\-\:\"\s]+$/;

/**
 * Constant for phone number minimum length required for field validation.
 *
 */
export const PHONE_NUMBER_MIN_LENGTH = 10;

/**
 * Constant for zip code minimum length required for field validation.
 *
 */
export const ZIP_CODE_MIN_LENGTH = 5;

/**
 * Custom generic formatter for a telephone number field. If the length of srcPhoneNumber is 10 or 11 (starting with a 1
 * prefix), we format as (###)###-####. In all other cases we display the number as received.
 *
 * @param srcPhoneNumber
 *        The telephone number to format.
 * @returns A string representing the formatted telephone number.
 */
export function phoneNumberFormatter(srcPhoneNumber) {
    try {
        // Step 1. if the phone number is null or empty, just return.
        if (srcPhoneNumber == null || srcPhoneNumber === "") {
            return "";
        }

        // Step 2. Strip non-digits from the phone number.
        let phoneAsDigits = srcPhoneNumber.replace("[^\\d]", "");

        // Step 3. If the 2 strings are not exactly equal, just return the srcPhoneNumber.
        if (srcPhoneNumber !== phoneAsDigits) {
            return srcPhoneNumber;
        }

        // Step 4. The phone number is all digits.
        // Step 4.a. Check if the phone number is 11 digits. If the first character is a 1,
        // strip the first digit so that the number is a 10-digit string. If not, just return the srcPhoneNumber.
        let phonePrefix = "";
        if (phoneAsDigits.length === 11) {
            let phoneFirstChar = phoneAsDigits.substring(0, 1);
            if (phoneFirstChar !== "1") {
                return srcPhoneNumber;
            }
            // Strip the first character of the string and use the remainder.
            phoneAsDigits = phoneAsDigits.substring(1);
            // In the future, set the prefix to non-empty (if required). E.g. "1 ". phonePrefix = "";
        }
        // Step 4.b. If the phone number is not 10 digits, return srcPhoneNumber.
        if (phoneAsDigits.length !== 10) {
            return srcPhoneNumber;
        }
        // Step 4.c. At this point, we have a 10-digit phone number.
        let formattedPhoneNumber = "";
        formattedPhoneNumber =
            phonePrefix +
            "(" +
            phoneAsDigits.substring(0, 3) +
            ") " +
            phoneAsDigits.substring(3, 6) +
            "-" +
            phoneAsDigits.substring(6, 10);
        return formattedPhoneNumber;
    } catch (e) {
        return "";
    }
}

/***********************************************
 * String, Number and Date enhancements (no overrides)
 ***********************************************/

/**
 *  @param date
 *        The date object to inject leading zeros for small numbers 0-9.
 *
 * @returns String (inject leading zero for small numbers 0-9)
 */
export const dateAsStringToNN = (dateAsString) => {
    return dateAsString.replace(/\b(\d)\b/g, "0$1");
};

/**
 * @returns String (yyyyMMdd)
 */
export const timestampAsyyyyMMdd = (date) => {
    return (
        dateAsStringToNN(date.toLocaleDateString("en-US")).slice(-4) +
        dateAsStringToNN(date.toLocaleDateString("en-US")).slice(0, 2) +
        dateAsStringToNN(date.toLocaleDateString("en-US")).slice(3, 5)
    );
};

/**
 * Formats a date object in the format of MMDDYYYY.
 *
 * @param date
 *        The date object to be formatted to MMDDYYYY.
 *
 * @returns A Date object formmated MMDDYYYY.
 */
export const formatDateAsMMDDYYYY = (date) => {
    return dateAsStringToNN(date.toLocaleDateString("en-US"));
};

/**
 * Formats a date object in the format of yyyyMMdd.
 *
 * @param date
 *        The date object to be formatted to yyyyMMdd.
 *
 * @returns A String formatted to yyyyMMdd.
 */
export const formatDateAsyyyyMMdd = (date) => {
    if (date === null || date === undefined) {
        return "";
    }

    //Months are zero indexed
    const unformattedMonth = (date.getMonth() + 1).toString();
    const unformattedDay = date.getDate().toString();

    //Format potential leading zeroes
    const year = date.getFullYear().toString();
    const month = unformattedMonth.length === 1 ? "0" + unformattedMonth : unformattedMonth;
    const day = unformattedDay.length === 1 ? "0" + unformattedDay : unformattedDay;

    return year + month + day;
};

/**
 * Formats a date object in the format of yyyyMMddHHmm.
 * This is getting the locale string due to a business requirement for all times to be Eastern,
 * ex. someone in China sets a date picker to 01/01/2022 at 12:30, the value submitted will be
 * 01/01/2022 at 12:30 EST.
 *
 * @param date
 *        The date object to be formatted to yyyyMMddHHmm.
 *
 * @returns A String formatted to yyyyMMddHHmm.
 */
export const formatDateAsyyyyMMddHHmm = (date) => {
    if (date === null || date === undefined) {
        return "";
    }

    //Months are zero indexed
    const unformattedMonth = (date.getMonth() + 1).toString();
    const unformattedDay = date.getDate().toString();
    const unformattedHours = date.getHours().toString();
    const unformattedMinutes = date.getMinutes().toString();

    //Format potential leading zeroes
    const year = date.getFullYear().toString();
    const month = unformattedMonth.length === 1 ? "0" + unformattedMonth : unformattedMonth;
    const day = unformattedDay.length === 1 ? "0" + unformattedDay : unformattedDay;
    const hours = unformattedHours.length === 1 ? "0" + unformattedHours : unformattedHours;
    const minutes = unformattedMinutes.length === 1 ? "0" + unformattedMinutes : unformattedMinutes;

    return year + month + day + hours + minutes;
};

/**
 * Formats a date object to return the time in either AM or PM.
 *
 * @param date
 *        The date object to be formatted to twelve hour time(AM/PM).
 *
 * @returns A Date object formatted to twelve hour time.
 */
export const formatTimeInEst = (date) => {
    let formattedTime = new Date(date).toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });
    return formattedTime;
};

/**
 * Formats a string object of a date in yyyyMMddHHmmss to return the time in MM/dd/yyyy hh:mm:ss AM/PM format.
 *
 * @param dateAsyyyyMMddHHmmss
 *        The string object to be formatted to MM/dd/yyyy hh:mm:ss AM/PM format.
 *
 * @returns A string object formatted to MM/dd/yyyy hh:mm:ss AM/PM format.
 */
export const convertTimestampToDisplayableTime = (dateAsyyyyMMddHHmmss) => {
    let formattedDateAsString = "";
    let year = dateAsyyyyMMddHHmmss.substring(0, 4); // yyyy
    let month = dateAsyyyyMMddHHmmss.substring(4, 6); // MM
    let day = dateAsyyyyMMddHHmmss.substring(6, 8); // dd
    let hours = dateAsyyyyMMddHHmmss.substring(8, 10); // HH
    let minutes = dateAsyyyyMMddHHmmss.substring(10, 12); // mm
    let seconds = dateAsyyyyMMddHHmmss.substring(12, 14); // ss

    // Check if the hours is greater than or equal to 12 to determine suffix.
    let suffix = hours >= 12 ? "PM" : "AM";
    // If the hours is more than 12, subtract 12 hours from the hours field.
    hours = hours > 12 ? hours - 12 : hours;
    // Check if the hours is "00" as string. If so then it is midnight.
    hours = hours === "00" ? 12 : hours;

    formattedDateAsString =
        month + "/" + day + "/" + year + " @ " + hours + ":" + minutes + ":" + seconds + " " + suffix;
    return formattedDateAsString;
};

/**
 * Formats a string object of a date in yyyyMMddHHmm to return the time in MM/dd/yyyy hh:mm AM/PM format.
 *
 * @param dateAsyyyyMMddHHmm
 *        A string representing a date in the format yyyyMMddHHmm.
 *
 * @returns A string representing a date in the format MM/dd/yyyy hh:mm AM/PM.
 */
export const convertyyyyMMddHHmmToDisplayableTime = (dateAsyyyyMMddHHmm) => {
    let formattedDateAsString = "";
    let year = dateAsyyyyMMddHHmm.substring(0, 4); // yyyy
    let month = dateAsyyyyMMddHHmm.substring(4, 6); // MM
    let day = dateAsyyyyMMddHHmm.substring(6, 8); // dd
    let hours = dateAsyyyyMMddHHmm.substring(8, 10); // HH
    let minutes = dateAsyyyyMMddHHmm.substring(10, 12); // mm

    // Check if the hours is greater than or equal to 12 to determine suffix.
    let suffix = hours >= 12 ? "PM" : "AM";
    // If the hours is more than 12, subtract 12 hours from the hours field.
    hours = hours > 12 ? hours - 12 : hours;
    // Check if the hours is "00" as string. If so then it is midnight.
    hours = hours === "00" ? 12 : hours;

    formattedDateAsString = month + "/" + day + "/" + year + " @ " + hours + ":" + minutes + " " + suffix;
    return formattedDateAsString;
};

/**
 * Formats a string object of a date in yyyyMMddHHmm to return the time in hh:mm AM/PM format.
 *
 * @param dateAsyyyyMMddHHmm
 *        A string representing a date in the format yyyyMMddHHmm.
 *
 * @returns A string representing a date in the format hh:mm AM/PM.
 */
export const convertyyyyMMddHHmmToDisplayableTimeInHHmm = (dateAsyyyyMMddHHmm) => {
    let formattedDateAsString = "";
    let unformattedHours = dateAsyyyyMMddHHmm.substring(8, 10); // HH
    let minutes = dateAsyyyyMMddHHmm.substring(10, 12); // mm

    // Check if the hours is greater than or equal to 12 to determine suffix.
    let suffix = unformattedHours >= 12 ? "PM" : "AM";
    // If the hours is more than 12, subtract 12 hours from the hours field.
    unformattedHours = unformattedHours > 12 ? unformattedHours - 12 : unformattedHours;
    // Check if the hours is "00" as string. If so then it is midnight.
    unformattedHours = unformattedHours === "00" ? 12 : unformattedHours;

    const unformattedHoursString = unformattedHours.toString();

    // Hours before 12 should be single digit.
    const hours =
        unformattedHoursString.length > 1 && unformattedHoursString[0] === "0"
            ? unformattedHoursString[1]
            : unformattedHours;

    formattedDateAsString = hours + ":" + minutes + " " + suffix;
    return formattedDateAsString;
};

/**
 * Formats a string object of a date in yyyyMMddHHmm to return a Javascript Date object.
 *
 * @param dateAsyyyyMMddHHmm
 *        A string representing a date in the format yyyyMMddHHmm.
 *
 * @returns A Date object.
 */
export const convertyyyyMMddHHmmToDate = (dateAsyyyyMMddHHmm) => {
    const year = dateAsyyyyMMddHHmm.substring(0, 4); // yyyy
    let month = dateAsyyyyMMddHHmm.substring(4, 6); // MM
    const day = dateAsyyyyMMddHHmm.substring(6, 8); // dd
    const hours = dateAsyyyyMMddHHmm.substring(8, 10); // HH
    const minutes = dateAsyyyyMMddHHmm.substring(10, 12); // mm

    // If month has leading zero and has a length greater than 1, use second digit
    if (month.length > 1 && month[0] === "0") {
        month = month[1];
    }

    // Create date, subtract 1 from month for the index value
    return new Date(year, month - 1, day, hours, minutes);
};

/**
 * given [optionally delimited] String (YYYY-MM-DD hh:mm:ss.nnn)
 * @returns Date
 */
String.prototype.toDate = function () {
    // 2-digit groups preferred
    let d = this.match(/\d\d?/g);
    // compose 4-digit year (YYYY)
    d.unshift(d.splice(0, 2).join(""));
    // compose 3-digit milliseconds (nnn)
    while (d.length > 7) d.push(d.splice(-2, 2).join(""));
    // set defaults for remaining params
    while (d.length < 7) d.push(d.length < 3 ? 1 : 0);
    d = d.map(Number);
    // Date constructor uses 0-based month!
    return new Date(d[0], d[1] - 1, d[2], d[3], d[4], d[5], d[6] % 1000);
};

/**
 * This exists to work around the formatter for Data Table
 * given number ex. 1648785600000
 * @returns Date
 */
Number.prototype.toDate = function () {
    return new Date(this);
};

/**
 * Maps an array of objects properties to React Select format of { value: propKey, label: propValue}
 *
 * @param array
 *        An array of Objects with non-nested key value pairs
 *
 * @returns An array of objects in the format React Select expects
 */
export const mapObjectArrayToReactSelect = (array) => {
    let reactSelectValues = [];

    array?.map((arrValues) => {
        return reactSelectValues.push({
            value: Object.keys(arrValues)[0],
            label: Object.values(arrValues)[0],
        });
    });

    return reactSelectValues;
};

/**
 * Maps an array of objects properties to React Select format of { value: propKey, label: propValue}
 *
 * @param array
 *        An array of Objects with 2 non-nested properties you would like to be assigned to "value" and "label"
 *
 * @param value
 *        A string representation of the object property you would like to assign to the value
 *
 * @param label
 *        A string representation of the object property you would like to assign to the label
 *
 * @returns An array of objects in the format React Select expects
 */
export const mapDualObjectArrayToReactSelect = (array, value, label) => {
    let reactSelectValues = [];

    array.forEach((arrayValue) => {
        reactSelectValues.push({ value: arrayValue[value], label: arrayValue[label] });
    });

    return reactSelectValues;
};

/**
 * Maps an array of strings to React Select format of { value: string, label: translate(string)} with included translations.
 *
 * @param stringArray
 *        An array of Strings.
 *
 * @param translate
 *        A translation function for i18n.
 *
 * @param translationFile
 *        A string with the label of the JSON file being used for translations.
 *
 * @returns An array of objects in the format React Select expects
 */
export const mapStringArrayToReactSelectWithTranslations = (stringArray, translate, translationFile) => {
    let reactSelectValues = [];

    stringArray?.map((arrValues) => {
        return reactSelectValues.push({
            value: arrValues,
            label: translate(arrValues, {
                ns: translationFile,
            }),
        });
    });

    return reactSelectValues;
};

/**
 * Maps an array of strings to React Select format, duplicating value and label.
 *
 * @param stringArray
 *        A string array for the value and label.
 *
 * @returns An array of objects in the format React Select expects
 */
export const mapStringArrayToReactSelect = (array) => {
    let reactSelectValues = [];

    array?.map((arrValues) => {
        return reactSelectValues.push({
            value: arrValues,
            label: arrValues,
        });
    });

    return reactSelectValues;
};

/**
 * Validate an input with a length greater than 0 against a regex.
 *
 * @param inputValue
 *        A string value you want to test.
 *
 * @param regex
 *        A regex you want to test your input against.
 *
 * @returns True if the test passes or if the string is empty, false it the test fails.
 */
export const validateInputAgainstRegex = (inputValue, regex) => {
    return regex.test(inputValue) || inputValue === "";
};

/**
 * Parse a file size in GB/MB/KB
 *
 * @param size
 *        A number value in bytes that you want to parse.
 *
 * @returns A string value representing the size.
 */
export const parseFileSize = (size) => {
    let precision = 1;
    let factor = Math.pow(10, precision);
    size = Math.round(size / 1000); // size in KB
    if (size < 1000) {
        return size + " KB";
    }
    size = parseFloat(size / 1000); // size in MB
    if (size < 1000) {
        return Math.round(size * factor) / factor + " MB";
    }
    size = parseFloat(size / 1000); // size in GB
    return Math.round(size * factor) / factor + " GB";
};

/**
 * Run text input against a name regex, if it passes, trigger onChange.
 * To be used with a React Hook Form field.
 *
 * @param onChange
 *        The onChange function provided by the React Hook Form controller.
 *
 * @param event
 *        The event handler provided by a normal on change event.
 *
 */
export const validateNameOnChange = (onChange) => (event) => {
    if (validateInputAgainstRegex(event.target.value, REGEX_NAME_ALLOWED_CHARACTERS)) {
        onChange(event);
    }
};

/**
 * Navigate to a user fallback page.
 *
 * @param location
 *        The current location object from the React Router useLocation hook.
 *
 * @param navigate
 *        The function to change locations from the React Router useNavigate hook.
 *
 * @param fallback
 *        A string representation of a route you want to fallback to if the previousLink does not exist, ex: "/home".
 *        Has a default value of root "/".
 */
export const navigateToPreviousLink = (location, navigate, fallback = "/") => {
    const previousLink = location?.state?.previousLink;
    if (previousLink !== undefined) {
        navigate(previousLink);
    } else {
        navigate(fallback);
    }
};

/**
 * Navigate to a user fallback page but include state.
 *
 * @param location
 *        The current location object from the React Router useLocation hook.
 *
 * @param navigate
 *        The function to change locations from the React Router useNavigate hook.
 *
 * @param fallback
 *        A string representation of a route you want to fallback to if the previousLink does not exist, ex: "/home".
 *        Has a default value of root "/".
 * 
 * @param state
 *        An object representing state you want to pass between components with useNavigate. ex. {
                    state: {
                        notification: getValues()?.emailAddress,
                    },
                }
 */
export const navigateToPreviousLinkWithState = (location, navigate, fallback = "/", state) => {
    const previousLink = location?.state?.previousLink;
    if (previousLink !== undefined) {
        navigate(previousLink, state);
    } else {
        navigate(fallback, state);
    }
};

/**
 * Scroll to top of page function.
 */
export const scrollToTopPage = () => {
    window.scrollTo(0, 0);
};

/**
 * Scroll to a part of the page based of the id element.
 *
 * @param scrollChoice
 *        The id of the element that the scrollIntoView function will scroll to.
 */
export const scrollToIdElement = (scrollChoice) => {
    let scroll = document.getElementById(scrollChoice);
    scroll.scrollIntoView({ behavior: "smooth" });
};

/**
 * Refresh the page if the route we click matches our current route.
 *
 * @param selectedPathname
 *        A string that represents the exact pathname we are visiting, needs to be translated to match route names.
 */
//
export const refreshSameRoute = (selectedPathname) => {
    if (window?.location?.pathname === selectedPathname) {
        window?.location?.reload();
    }
};

/**
 * Replaces ampersands, less than, greater than, and single quote characters with their html escaped equivalence.
 *
 * @param str
 *        A string that needs its html characters escaped.
 */
export const lcpEscapeHtml = (str) => {
    return String(str)?.replace(/&/g, "&amp;")?.replace(/</g, "&lt;")?.replace(/>/g, "&gt;")?.replace(/"/g, "&quot;");
};

/**
 * Replaces ampersands, less than, greater than, and single quote characters with their html escaped equivalence,
 * execpt that break line tags are unaffected.
 *
 * @param str
 *        A string that needs its html characters escaped.
 */
export const lcpEscapeHtmlPreserveLineBreaks = (str) => {
    // Replace the <br /> html element with @@br@@ temporarily.
    let modifiedStr = str?.replaceAll("<br />", "@@br@@");
    // Replace other html strings with escaped characters.
    let newModifiedStr = lcpEscapeHtml(modifiedStr);
    // Revert @@br@@ back to <br /> html element.
    newModifiedStr = newModifiedStr?.replaceAll("@@br@@", "<br />");
    return newModifiedStr;
};
