/**
 * The default value for minimum password length fields.
 */
export const DEFAULT_MIN_PASSWORD_LENGTH = 8;

/**
 * The default value for maximum password length
 */
export const DEFAULT_MAX_PASSWORD_LENGTH = 50;

/**
 * The color code for toggle on switch.
 */
export const TOGGLE_SWITCH_ON_COLOR = "#E0FF00";

/**
 * The color code for toggle off switch.
 */
export const TOGGLE_SWITCH_OFF_COLOR = "#E6E6E6";
