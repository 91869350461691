import { createContext, useCallback, useMemo } from "react";

//Create the context
//https://beta.reactjs.org/apis/createcontext#creating-context
export const GlobalContext = createContext();

//Provider for the global context, children prop refers to any components that need to use the context.
export const ContextProvider = ({ children }) => {
    //Retrieve first name
    const getFirstName = useCallback(() => {
        return localStorage.getItem("firstName");
    }, []);

    //Retrieve full name
    const getFullName = useCallback(() => {
        return localStorage.getItem("fullName");
    }, []);

    //Retrieve user name
    const getUserName = useCallback(() => {
        return localStorage.getItem("userName");
    }, []);

    //Update the logged in user first name.
    const updateFirstName = useCallback((item) => {
        localStorage.setItem("firstName", item);
    }, []);

    //Update the logged in user full name.
    const updateFullName = useCallback((item) => {
        localStorage.setItem("fullName", item);
    }, []);

    //Update the logged in user name.
    const updateUserName = useCallback((item) => {
        localStorage.setItem("userName", item);
    }, []);

    //Values to expose for the context
    const value = useMemo(
        () => ({
            getFirstName,
            getFullName,
            getUserName,
            updateFirstName,
            updateFullName,
            updateUserName,
        }),
        [getFirstName, getFullName, getUserName, updateFirstName, updateFullName, updateUserName]
    );

    return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};
