import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { Base64 } from "js-base64";
import { useCallback, useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { GlobalContext } from "../../components/Context";
import Footer from "../../components/Footer";
import LogoBar from "../../components/LogoBar";
import NotificationBar from "../../components/NotificationBar";
import OverlaySpinner from "../../components/OverlaySpinner";
import { clientAuthenticationToken, clientPublic } from "../../services/client";
import { DEFAULT_MAX_PASSWORD_LENGTH, DEFAULT_MIN_PASSWORD_LENGTH } from "../../static/commonConstants";
import { NOTIFICATION_CODE } from "../../static/notificationCodes";
import { routeDictionaryReverse, routePostFix } from "../../static/routeDictionary";
import { XHR_STATUS } from "../../static/xhrStatus";

export default function SignIn() {
    //Hide snap engage button
    window.snapEngageVisibleFlag = false;
    window.SnapEngage?.hideButton();

    // State for the notification bar for error messages.
    const [showErrorNotification, setShowErrorNotification] = useState(false);

    // State for the notification bar for locked messages.
    const [showLockedNotification, setShowLockedNotification] = useState(false);

    // State for the text on the notification bar error message.
    const [errorNotificationText, setErrorNotificationText] = useState();

    // State for the notification bar for success messages.
    const [showSuccessNotification, setShowSuccessNotification] = useState(false);

    // State for the text on the notification bar success message.
    const [successNotificationText, setSuccessNotificationText] = useState();

    // State for the minimum password length (hard coded 8 if we fail to get the minimum length from password policy).
    const [minPasswordLength, setMinPasswordLength] = useState(DEFAULT_MIN_PASSWORD_LENGTH);

    // State for the maximum password length (hard coded 50 if we fail to get the maximum length from password policy).
    const [maxPasswordLength, setMaxPasswordLength] = useState(DEFAULT_MAX_PASSWORD_LENGTH);

    // Hook into navigation for react router dom.
    const navigate = useNavigate();

    // Global context.
    const context = useContext(GlobalContext);

    // Hook into location for react router dom.
    const location = useLocation();

    // Function from i18next for translation.
    const { t: translate } = useTranslation(["signin", "routes"]);

    // Potential incoming notifications.
    const incomingNotification = location.state?.notification;

    // useEffect to assign potential notifications onload.
    useEffect(() => {
        if (incomingNotification) {
            if (incomingNotification === NOTIFICATION_CODE.CHANGE_PASSWORD_SUCCESS) {
                setSuccessNotificationText(translate("changePassword.case.success"));
            }
            setShowSuccessNotification(true);
        }
    }, [incomingNotification, translate]);

    // Initial values for the form fields.
    const initialFormValues = {
        userNameOrEmailAddress: "",
        password: "",
    };

    // Declare validation schema for form fields.
    const validationSchema = Yup.object().shape({
        userNameOrEmailAddress: Yup.string().required(),
        password: Yup.string().required().min(minPasswordLength),
    });

    // React hook form for form fields validation.
    const {
        handleSubmit,
        control,
        getValues,
        setValue,
        resetField,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: initialFormValues,
        mode: "onTouched",
    });

    // Query method to check if you are already logged in.
    useQuery(
        ["signin"],
        () => {
            return clientAuthenticationToken.post(
                process.env.REACT_APP_APP_SERVER_URL + "/lcp-user-management-service/authenticateToken"
            );
        },
        {
            onSuccess: () => {
                if (window.snapEngageVisibleFlag === false) {
                    window.snapEngageVisibleFlag = true;
                    window.SnapEngage?.showButton();
                }
                navigate("/");
            },
        }
    );

    // Query method to get the password policies.
    const getPasswordPolicy = useQuery(
        ["/lcp-user-management-service/getPasswordPolicy"],
        () => {
            return clientPublic.post(
                process.env.REACT_APP_APP_SERVER_URL + "/lcp-user-management-service/getPasswordPolicy"
            );
        },
        {
            onSuccess: (innerData) => {
                setMinPasswordLength(innerData?.data?.lcpPasswordPolicyBean?.minPasswordLength);
                setMaxPasswordLength(innerData?.data?.lcpPasswordPolicyBean?.maxPasswordLength);
            },
        }
    );

    // Mutation query to authenticate/login a user.
    const login = useMutation(
        ["/lcp-user-management-service/authenticate"],
        () => {
            // Make the notification bar disappear when attempting to login if it is shown.
            turnOffAllNotifications();
            const values = getValues();
            const userNameOrEmailAddress = values.userNameOrEmailAddress.trim();
            const password = values.password.trim();
            return axios.post(process.env.REACT_APP_APP_SERVER_URL + "/lcp-user-management-service/authenticate", {
                userNameOrEmailAddress: Base64.encode("x" + userNameOrEmailAddress),
                password: Base64.encode("x" + password),
            });
        },
        {
            onSuccess: (data) => {
                // Update the user information globally
                context?.updateFirstName(data?.data?.firstName);
                context?.updateFullName(data?.data?.firstName + " " + data?.data?.lastName);
                context?.updateUserName(data?.data?.userName);

                //  Set the authentication token in local storage
                localStorage.setItem("lcpAuthToken", data?.data?.authenticationToken);

                //Check session storage
                const from = sessionStorage.getItem("from") || "/";

                //Get rid of the from item
                sessionStorage.removeItem("from");

                //  Redirect to the path we came from or Home
                navigate(from);
            },
            onError: (error) => {
                if (error.message === "Network Error") {
                    navigate("/ext-temporary-issue");
                }

                //Navigate to the maintenance page for 503
                if (error?.response?.status === 503) {
                    window.location = process.env.REACT_APP_APP_SERVER_URL + "/maintenance/";
                    return;
                }

                //Clear the password field
                resetField("password");

                //Determine message to display
                const locked = handleErrorReason(error);

                if (!locked) {
                    //Show the notification bar.
                    setShowErrorNotification(true);
                }
            },
        }
    );

    // Handle form submission for Sign in.
    const onSubmit = () => {
        login.mutate();
    };

    const handleErrorReason = (reason) => {
        let lockedNotificationShown = false;

        switch (reason?.response?.status) {
            //Bad request case.
            case 400: {
                //Const for the translation key.
                const xhrStatus = reason?.response?.data?.xhrStatus;
                // Check if the user has an expired password.
                if (xhrStatus?.statusCode === XHR_STATUS.XHR_PASSWORD_EXPIRED) {
                    navigate("/reset-password?token=" + reason?.response?.data?.token + "&prAction=ep");
                }
                if (xhrStatus?.statusCode === XHR_STATUS.XHR_CUSTOMER_AUTH_FAILURE) {
                    navigate("/account-profile-problem");
                }
                break;
            }
            //Authentication failures should display a message
            case 401: {
                //Const for the translation key.
                const xhrStatus = reason?.response?.data?.xhrStatus;

                switch (xhrStatus?.statusCode) {
                    case XHR_STATUS.XHR_INACTIVE_USER: {
                        setErrorNotificationText(translate("signin.inactive.user"));
                        break;
                    }
                    case XHR_STATUS.XHR_INTERNAL_USER_LOCKED_OUT_FAILURE:
                    case XHR_STATUS.XHR_CUSTOMER_ADMIN_LOCKED_OUT_FAILURE:
                    case XHR_STATUS.XHR_USER_LOCKED_OUT_FAILURE: {
                        setShowLockedNotification(true);
                        lockedNotificationShown = true;
                        break;
                    }
                    default: {
                        setErrorNotificationText(translate("signin.authentication.failed"));
                        break;
                    }
                }
                break;
            }
            //Forbidden should display a message unless they have insufficient permissions, then it should redirect.
            case 403: {
                const xhrStatus = reason?.response?.data?.xhrStatus;

                if (xhrStatus === XHR_STATUS.XHR_USER_INSUFFICIENT_PERMISSION) {
                    navigate("/permission-issue");
                } else {
                    navigate("/account-profile-problem");
                }
                break;
            }
            //This should redirect to temporary issue page.
            case 500: {
                //Const for the translation key.
                const xhrStatus = reason?.response?.data?.xhrStatus;
                switch (xhrStatus?.statusCode) {
                    case XHR_STATUS.XHR_DATABASE_FAILURE:
                        navigate("/technical-issue");
                        break;
                    case XHR_STATUS.XHR_GENERAL_FAILURE:
                        navigate("/ext-temporary-issue");
                        break;
                    default:
                        navigate("/temporary-issue");
                        break;
                }
                break;
            }
            //504, 502 should navigate to public temp issue.
            case 502:
            case 504: {
                navigate("/ext-temporary-issue");
                break;
            }
            //Generic error for uncaught issues.
            default: {
                setErrorNotificationText("Login Failed: Please try again.");
                break;
            }
        }

        return lockedNotificationShown;
    };

    // Method for turning off all notifications currently displayed.
    const turnOffAllNotifications = () => {
        setShowErrorNotification(false);
        setShowSuccessNotification(false);
        setShowLockedNotification(false);
    };

    // onChange callback function that validates the user name or email address input and sets it.
    const userNameOrEmailAddressOnChange = useCallback(
        (onChange) => (event) => {
            onChange(event);
        },
        []
    );

    // onBlur callback function to trim whitespace on userNameOrEmailAddress.
    const userNameOrEmailAddressOnBlur = useCallback(
        (onBlur) => (event) => {
            setValue("userNameOrEmailAddress", event.currentTarget.value.trim(), {
                shouldValidate: false,
                shouldDirty: false,
            });
            onBlur(event);
        },
        [setValue]
    );

    // Callback function for username or email address form field.
    const userNameOrEmailAddressCallback = useCallback(
        ({ field: { name, ref, onBlur, onChange, value } }) => (
            <Form.Control
                type="text"
                name={name}
                ref={ref}
                autoComplete="userNameOrEmailAddress"
                value={value}
                onBlur={userNameOrEmailAddressOnBlur(onBlur)}
                maxLength={320}
                isInvalid={errors?.userNameOrEmailAddress}
                onChange={userNameOrEmailAddressOnChange(onChange)}
                autoFocus
            />
        ),
        [userNameOrEmailAddressOnBlur, errors?.userNameOrEmailAddress, userNameOrEmailAddressOnChange]
    );

    // onChange callback function that validates the password input and sets it.
    const passwordOnChange = useCallback(
        (onChange) => (event) => {
            onChange(event);
        },
        []
    );

    // onBlur callback function to trim whitespace on password.
    const passwordOnBlur = useCallback(
        (onBlur) => (event) => {
            setValue("password", event.currentTarget.value.trim(), {
                shouldValidate: false,
                shouldDirty: false,
            });
            onBlur(event);
        },
        [setValue]
    );

    // Callback function for password form field.
    const passwordCallback = useCallback(
        ({ field: { name, ref, onBlur, onChange, value } }) => (
            <Form.Control
                type="password"
                name={name}
                ref={ref}
                autoComplete="current-password"
                value={value}
                onBlur={passwordOnBlur(onBlur)}
                maxLength={maxPasswordLength}
                isInvalid={errors?.password}
                onChange={passwordOnChange(onChange)}
            />
        ),
        [errors?.password, maxPasswordLength, passwordOnBlur, passwordOnChange]
    );

    // Render the SignIn page.
    return (
        <>
            <Helmet>
                <title>{translate(routeDictionaryReverse[location?.pathname], { ns: "routes" }) + routePostFix}</title>
                <meta
                    name="description"
                    content="Sign In to your Lightpath account today. Learn more about our Wifi, business Internet and business
                    phone for small businesses and medium-sized businesses with Lightpath."
                />
            </Helmet>
            {(login?.isLoading || getPasswordPolicy?.isLoading) && <OverlaySpinner />}

            <LogoBar src={process.env.REACT_APP_STATIC_IMAGES + "/lp-logo.png"} alt="Lightpath Logo" />
            <div className="lcp lcp-container-form lcp-public-block-container">
                <Container>
                    <h1 className="text-center">{translate("signin.page.title")}</h1>
                    <Row>
                        <Col xs={{ offset: 1, span: 10 }} sm={{ offset: 2, span: 8 }}>
                            <Row>
                                <Col xs={{ offset: 1, span: 10 }} sm={{ offset: 2, span: 8 }}>
                                    {showErrorNotification && (
                                        <NotificationBar errorMessageFlag={true} message={errorNotificationText} />
                                    )}
                                    {showSuccessNotification && (
                                        <NotificationBar successMessageFlag={true} message={successNotificationText} />
                                    )}
                                    {showLockedNotification && (
                                        <NotificationBar
                                            errorMessageFlag={true}
                                            message={
                                                <Trans i18nKey={"signin.lockedout.all.users"}>
                                                    Your account has been locked. <br /> Please{" "}
                                                    <Link className="lcp-link-white" to="/request-reset-password">
                                                        reset your password
                                                    </Link>
                                                    .
                                                </Trans>
                                            }
                                        />
                                    )}
                                </Col>
                            </Row>
                            <br />
                            <Form onSubmit={handleSubmit(onSubmit)} id="loginForm" noValidate autoComplete={"on"}>
                                {/* Username or Email Address field. */}
                                <Row>
                                    <Col sm={{ offset: 2, span: 8 }}>
                                        <Form.Group className="lcp-form-group" controlId="formBasicEmail">
                                            <Form.Label
                                                className={
                                                    errors?.userNameOrEmailAddress
                                                        ? "required lcp-label-error"
                                                        : "required"
                                                }
                                            >
                                                {translate("signin.label.username.or.emailAddress")}
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name="userNameOrEmailAddress"
                                                render={userNameOrEmailAddressCallback}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {/* Password field. */}
                                <Row>
                                    <Col sm={{ offset: 2, span: 8 }}>
                                        <Form.Group className="lcp-form-group" controlId="formBasicPassword">
                                            <Form.Label
                                                className={errors?.password ? "required lcp-label-error" : "required"}
                                            >
                                                {translate("signin.label.password")}
                                            </Form.Label>
                                            <Controller control={control} name="password" render={passwordCallback} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {/* Sign In Button */}
                                <Row className="lcp-button-row align-items-center">
                                    <Col sm={{ offset: 4, span: 4 }}>
                                        <Row>
                                            <Col md={{ offset: 1, span: 10 }}>
                                                <Button type="submit" formid="loginForm" className="lcp-button">
                                                    {translate("signin.button.signin")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={{ offset: 1, span: 3 }} className="text-center">
                                        <Link to="/request-reset-password">
                                            {translate("signin.forgotPassword.link")}
                                        </Link>
                                    </Col>
                                    <Col sm={{ offset: 4, span: 3 }} className="text-center">
                                        <Link to="/request-access">{translate("signin.request.access.prompt")}</Link>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    );
}
