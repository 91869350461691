/**
 * Refresh the page if the chunk being retrieved for a lazy loaded component is out of date
 *
 * @param componentImport
 *        Import statement for the component chunk we are trying to retrieve.
 *
 */
export default function lazyRetry(componentImport) {
    return new Promise((resolve, reject) => {
        //Check if a refresh has already happened.
        const hasRefreshed = JSON.parse(window.sessionStorage.getItem("retry-lazy-refreshed") || "false");

        //Trigger import
        componentImport()
            .then((component) => {
                //Chunk is successfully retrieved, do not refresh
                window.sessionStorage.setItem("retry-lazy-refreshed", "false");
                resolve(component);
            })
            .catch((error) => {
                if (!hasRefreshed) {
                    //Reload if the page has not been refreshed yet
                    window.sessionStorage.setItem("retry-lazy-refreshed", "true");
                    return window.location.reload();
                }

                //Error is unrelated, pass through
                reject(error);
            });
    });
}
